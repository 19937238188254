function controllerForms() {
    const init = function () {
        //REGISTRATION
        $('body').on('change', '.author_switch', function () {
            if ($(this).is(':checked')) {
                $('.author_registration_block').slideDown();
            } else {
                if (!$('.publishing_co_switch').is(':checked')) {
                    $('.author_registration_block').slideUp();
                }
            }
        });

        $('body').on('change', '.publishing_co_switch', function () {
            if ($(this).is(':checked')) {
                $('.author_registration_block').slideDown();
            } else {
                if (!$('.author_switch').is(':checked')) {
                    $('.author_registration_block').slideUp();
                }
            }
        });

        $('.payment_method').change(function () {
            if ($(this).val() == '0') {
                $('.moip_fields').slideDown();
                $('.bank_fields').slideUp();
            }
            if ($(this).val() == '1') {
                $('.moip_fields').slideUp();
                $('.bank_fields').slideDown();
            }
        });

        $('.birthday_mask').mask('99/99/9999');
        $('.cep_mask').mask('99999-999');

        $('body').on('keyup', '.cep_mask', function () {
            if ($(this).val().length == '9') {
                var cep = $(this).val().replace('-', '');
                $('.focus_number').focus();
                $.ajax({
                    url: 'https://viacep.com.br/ws/' + cep + '/json/',
                    success: function (data) {
                        $('.fill_uf').val(data.uf);
                        $('.fill_logradouro').val(data.logradouro);
                        $('.fill_localidade').val(data.localidade);
                        $('.fill_bairro').val(data.bairro);
                    }
                });
            }
        });

        $('body').on('blur', '.focus_number', function () {
            $('.focus_complement').focus();
        });

        var SPMaskBehavior = function (val) {
            return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        },
            spOptions = {
                onKeyPress: function (val, e, field, options) {
                    field.mask(SPMaskBehavior.apply({}, arguments), options);
                }
            };

        $('.phone_mask').mask(SPMaskBehavior, spOptions);

        $('.cpf_cnpj_mask').mask('999.999.999-999');
        $('body').on('keyup', '.cpf_cnpj_mask', function () {
            if ($(this).val().length >= 15) {
                $(this).unmask();
                $(this).mask('99.999.999/9999-99');
                $(this)[0].selectionStart = this.selectionEnd = this.value.length;
                if ($(this).hasClass('switch_fields')) {
                    $('.author_has_cpf').slideUp();
                    $('.author_has_cnpj').slideDown();
                }
            } else {
                $(this).unmask();
                $(this).mask('999.999.999-999');
                $(this)[0].selectionStart = this.selectionEnd = this.value.length;
                if ($(this).hasClass('switch_fields')) {
                    $('.author_has_cpf').slideDown();
                    $('.author_has_cnpj').slideUp();
                }
            }
        });

        //BOOK SINGLE
        $('body').on('click', '.book_single_info-controls-version-radio:not(.disabled)', function () {

            if ($(this).closest('.book_single_info-controls-version-option').hasClass('active'))
                return false;

            $('.book_single_info-controls-version-option').toggleClass('active');

        });

        //HOME SIMULATOR
        $('.format_money').maskMoney();
        $('body').on('click', '.simulation_form .common_fake_checkbox', function () {
            $('.home_simulator-form-error[data-error=type]').slideUp();
        });
        $('body').on('click', '.home_simulator-form-book_type_radio', function () {
            $('.home_simulator-form-error[data-error=format]').slideUp();
        });
        $('body').on('change', '.simulation_form select', function () {
            $(this).removeClass('error');
        });
        $('body').on('keyup', '.simulation_form input', function () {
            $(this).removeClass('error');
        });
        $('body').on('submit', '.simulation_form', function () {
            var error = 0;

            if ($('.simulation_form .common_fake_checkbox input:checked').length == 0) {
                $('.home_simulator-form-error[data-error=type]').slideDown();
                error++;
            }

            if ($('.simulation_form .home_simulator-form-book_type_radio input:checked').length == 0) {
                $('.home_simulator-form-error[data-error=format]').slideDown();
                error++;
            }

            $('.simulation_form input').each(function () {
                if ($(this).val() == '') { $(this).addClass('error'); }
            });

            $('.simulation_form select').each(function () {
                if ($(this).find('option:selected').val() == '') { $(this).addClass('error'); }
            });

            if ($('.simulation_form .error').length > 0) {
                error++;
            }

            if (error == 0) {
                $('.home_simulator-result_box-step.step1').fadeOut();
                $('.home_simulator-result_box-step.step2').fadeIn();
                $('.home_simulator-result_box-step-form-field:eq(0) input').focus();
            }

            return false;
        });

        $('body').on('submit', '.home_simulator-result_box-step.step2 form', function () {
            $('.home_simulator-result_box-step.step2').fadeOut();
            $('.home_simulator-result_box-step.step3').fadeIn();
            return false;
        });
    }

    return { init: init }
}

export default controllerForms()
