function controllerUser() {
    const init = function() {
        $('body').on('click', '.user-form__links__link__button', function () {
            if ($(this).hasClass('remove')) {
                $(this).closest('.user-form__links__link').hide();
            } else {
                var clonedLink = $(this).closest('.user-form__links').find('.user-form__links__link:first-child').clone();
                clonedLink.find('input').val('');
                $(this).closest('.user-form__links').append(clonedLink);


                // Evita que mais de um registro vá com a mesma chave 0.
                $('.user-form__links__link').each(function(e) {
                    var key = Math.floor(Math.random() * 1000);
                    $(this).find('input').each(function() {
                       $(this).attr('name' , $(this).attr('name').replace(/\d/g, key));
                    })
                });
            }
            return false;
        });

        $('body').on('change', '.user_photo', function (e) {
            $('.user-form__photo__icon').css('background-image', 'url(' + URL.createObjectURL(e.target.files[0]) + ')').addClass('set');
        });

        $('body').on('change','.get_book_badge',function(){
            var book = $(this).find('option:selected').val();
            $('.user-acquired-badges.switchable').slideUp();
            $('.user-acquired-badges.switchable[data-book='+book+']').slideDown();
        });

        $('body').on('click','.user-sidebar.admin span',function(){
            $(this).parent().toggleClass('active');
        });

        $('body').on('click','.user-sidebar-button',function(){
            $('.user-sidebar').toggleClass('active');
            $(this).toggleClass('active');
        });
    }
    return { init: init }
}

export default controllerUser();
